@import '../../theme/variables';

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 0;
  background-color: $color-white;

  &__logo {
    width: auto;
    max-height: 8rem;
    margin-right: 4rem;
    padding-right: 3rem;
    border-right: 1px solid #bcbdc0;

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }
}
