@import '../../theme/variables';

.lifts {
  color: $color-white;
  font-weight: $font-bold;
  font-size: 3.4rem;
  line-height: 4.1rem;
  display: flex;
  align-items: center;

  &__dot {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    margin-right: 3rem;
    background-color: $color-green;

    &Red {
      @extend .lifts__dot;

      background-color: $color-red;
    }
  }

  &__icon {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    margin-right: 3rem;
  }
}
