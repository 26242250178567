@import '../../theme/variables';

.alert {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1.2rem 4rem;
  background: $color-blue;
  color: $color-white;
  font-weight: $font-bold;
  font-size: $font-size-m;
  line-height: $line-height-s;
  z-index: 1;

  &Warning {
    @extend .alert;

    background: $color-red;
  }

  &__icon {
    width: 3.6rem;
    height: 3.2rem;
    margin-right: 2rem;
  }

  &__text {
    margin-left: 2rem;
    padding-left: 2rem;
    border-left: 0.2rem solid $color-white;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 77.3rem;
  overflow: hidden;
}

.slider {
  width: 100%;
  height: 77.3rem;
  opacity: 1;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  left: auto;
  right: auto;
}

.camera {
  position: relative;
  width: 100vw;
  height: 77.3rem;
  opacity: 1;
  transition: ease 0.4s all;
  color: $color-white;

  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(49, 71, 112, 0.86);
    padding: 3rem 4rem;
  }

  &__title {
    display: flex;
    align-items: center;

    font-weight: $font-bold;
    font-size: $font-size-xl;
    line-height: 3.6rem;
    letter-spacing: 0.075rem;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__dot {
    width: 3.5rem;
    height: 3.5rem;
    background-color: $color-green;
    border-radius: 50%;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  &__text {
    font-weight: $font-regular;
    font-size: 3.4rem;
    line-height: 4.1rem;
    letter-spacing: 0.075rem;
  }

  &__icon {
    width: 4.1rem;
    height: 4.1rem;
    margin-right: 2rem;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.line {
  width: 0.2rem;
  height: 4rem;
  background-color: $color-white;
  margin: 0 7rem 0 8rem;
}
