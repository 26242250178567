.wrapper {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.innerWrapper {
  width: 55.1rem;
  height: 100vh;
}

.slider {
  width: 136.9rem;
  height: 100vh;
}

.advertisement {
  width: 136.9rem;
  height: 30.7rem;

  &__image {
    width: 100%;
    height: 100%;
  }
}

.background {
  width: 100%;
  height: 37rem;
  background-image: url('../assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
}
